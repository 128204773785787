<template>
  <v-form @submit.prevent="onSubmit" ref="form">
    <v-text-field
      label="Профиль"
      v-model="formData.name"
      :rules="[rules.required]"
      validate-on-blur
    ></v-text-field>
    <FormButtons />
  </v-form>
</template>

<script>
import FormButtons from '@/components/buttons/FormButtons.vue'

import { addProfile, editProfile } from '@/lib/requestsAPI'

import { routeParams } from '@/mixins/routeParams'
import { formMixin } from '@/mixins/formMixin'
import { sendFormWithToastAndRedirect } from '@/mixins/sendFormWithToastAndRedirect'

import { PROFILES, ADMIN, PROFILE_SHORT } from '@/store/const/path'

export default {
  mixins: [formMixin, routeParams, sendFormWithToastAndRedirect],
  components: {
    FormButtons,
  },
  data() {
    return {
      formData: {},
      TEMPLATE_FORM_DATA: {
        name: '',
      },
      rules: {
        required: v => !!v || 'Обязательное поле',
      },
    }
  },
  computed: {
    successPath() {
      return `/${ADMIN}/${PROFILES}/${PROFILE_SHORT}-`
    },
  },
  methods: {
    add() {
      this.sendFormWithToastAndRedirect({
        action: addProfile,
        data: this.formData,
        successPath: this.successPath,
        updateSuccessPath: true,
        successMessage: `Профиль «${this.formData.name}» добавлен`,
        errorMessage: 'Что-то пошло не так с добавлением профиля',
      })
    },
    edit() {
      this.sendFormWithToastAndRedirect({
        action: editProfile,
        data: { id: this.profileId, data: this.formData },
        successPath: `${this.successPath}${this.profileId}`,
        updateSuccessPath: false,
        successMessage: `Профиль «${this.formData.name}» отредактирован`,
        errorMessage: `Что-то пошло не так с редактированием профиля ${this.profileId}`,
      })
    },
  },
}
</script>
